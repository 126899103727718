import React, { Component } from 'react';
import '../../App.css';
import '../Reading_menu.css'
import ReadingMenu from '../Readings_menu';
import ReadingSamples from '../Reading_samples';

class Readings extends Component {
  componentDidMount() {
    window.scrollTo(0, 50);
  }
  render() {
  return (
    <>
    <div className="reading_container">
  
        <ReadingMenu />
        <ReadingSamples/>
   
    </div>
    </>
  );
}
}

export default Readings;
