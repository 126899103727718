import React from 'react';
import WritingItem from './Writing_item';
import { Button } from './Button';
import './home.css';




function WritingList() {
  return (
    <>

     <div className="Writing_list">
		 <div className="Container_Writing_list">
			<div className="works_pic">
			</div>
			<div className="works_list">
				<h1>Something to Read?</h1>
				<hr/>
			<WritingItem
              type='Featured Poem'
              Date='March 10, 2020'
              Title='I sing “Hallelujah!” because I think God might be listening'
			  Preview='A poem about the uncertainities and changes that COVID19 brought in its first weeks.' 
              path='writings#ISH'
            />
			
				<hr/>
			<WritingItem
                    type='Featured Story'
					Date='May 17, 2016'
					Title='At the Brewery'
					Preview="An alcoholic's last day of work at a brewery comes sooner than he had planned."
					path='writings#ATB'
            />
             
		
                <hr/>

				<WritingItem
              type='Featured Poem'
              Date='Feb 14, 2016'
              Title='Trumpet’s Ode to Clarinet'
			  Preview="At Hermann's Jazzclub, Trumpet speaks to his old love, Clarinet."
              path='writings#TOTC'
            />
			
				<hr/>
			<WritingItem
                    type='Featured Poem'
					Date='April 5, 2017'
					Title='The wind picked up by the water'
					Preview="A love poem, about letting go."
					path='writings#WPBW'
            />
				<div className='Blog_News_Button'>
			
                <Button buttonStyle='btn--primary' path ='writings'>Browse More Works</Button>

            		 
        		</div>
			</div>
			
		</div>
     

	</div>

    </>
  );
}

export default WritingList;
