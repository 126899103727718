import React from 'react';
import '../../App.css';
import Intro from '../intro';
import WaterColorPoster from '../WaterColorPoster';
import WritingList from '../WritingList';
import BlogNews from '../Blog_News';

function Home() {
  return (
    <>
    <div className="Home_divider"></div>
    <div className="home_container">
    
  
        <Intro />
        <WritingList/>
        
        <div className="Home_divider"></div>
        <WaterColorPoster/>
        
        <div className="Home_divider"></div>
        <BlogNews/>
        <div className="Home_divider"></div>
    </div>
    </>
  );
}

export default Home;
