import React from 'react';
import '../../App.css';
import '../Contact.css';
import '../project.css';
import ProjectItem from '../Project_item';

function Contact() {
  return (
    <>  
    <div className="Contact_body">
    <div className="Contact_container">
   <ProjectItem
    firstfirst='true' 
    second={
      
      <div className="message_2U">
        <h2>Contact Me</h2>
    
        Have an idea for a blog? Something else? Get in touch! I'm pretty friendly.<br/><br/>
        Book me to speak, to host, or to read.
        <br/><br/>
    
        Even in this tricky digital age we find ourselves in:&nbsp; I come Zoom-savvy and qualified.  
        <br/>
         <p>Email:&nbsp;  siljakellwrites@gmail.com</p>
         <br/>
        <i>And check back for information on poetry & writing workshops in the future for all levels and ages!</i>
      
   </div>
    
    } 
    first={	<div className="Contact_pics">
      <img src="/images/Boooks.jpg" alt="intro_pic"/>      
      </div>} />
    
    
   </div>
   </div>



    </>
  );
}

export default Contact;