import React from 'react';
import './Reading_samples.css';
import PoemItem from './Poem_item';
import TOTCItem from './writing/Trumpets'
import FOBTHItem from './writing/FOBTH'
import ISHItem from './writing/ISH'
import WPBWItem from './writing/WPBW'
import ATBItem from './writing/Brewery'
import HHItem from './writing/hardHands'

function ReadingSamples() {
  return (
<div class="container">
    <h1>Poems</h1>
    <hr/>
  <div className="poemlist">
    <PoemItem
     Link='https://www.meniscus.org.au/Vol7_2.pdf'
     poem_id='52'
     publisher='Meniscus Literary Journal'
     Issue=" Volume 7. Issue 2 "
     set_id={true}
     section='WBL'
     Title='It will be love that kills me'/>
    <PoemItem
     Link='https://www.meniscus.org.au/Vol7_2.pdf'
     poem_id='53'
     publisher='Meniscus Literary Journal'
     Issue=" Volume 7. Issue 2 "
     set_id={true}
     section='WBW'
     Title='We became wolves'/>
    <TOTCItem/>
    <WPBWItem/>
    <ISHItem/> 
    <FOBTHItem/>
    
    </div>
    <h1>Short Stories</h1>
    <hr/>
    <div className="storylist">
    <ATBItem/>
    </div>

    <h1>Other</h1>
    <hr/>
    <div className="storylist">
    <HHItem/>

    
    </div>
  </div>
  );
}

export default ReadingSamples;