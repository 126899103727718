import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'
import './subform.css'

class SubForm extends Component {
  render() {
    return (
        <div id="mailchimp">
         <h3>Subscribe for Updates</h3>
        <Mailchimp
        action={process.env.REACT_APP_sub_form_id}
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'em  ail',
            required: true
          }
        ]}
        />
        </div>
    );
  }
}

export default SubForm;