import React from 'react';
import './project.css';


function ProjectItem(props) {
    const firstfirst = props.firstfirst;
    if (firstfirst === "true") {
      return(
        <>
        {props.first}
        {props.second}
        </>
      );
    }

    else{
     return(
     <>
           {props.second}
           {props.first}
     </>
     );
    }
}

export default ProjectItem;