import React, {Component} from 'react';
import '../../App.css';
import '../Blog.css';
import '../project.css'
import { HashLink as Link } from 'react-router-hash-link';

class Opening_Image extends Component {
 
  componentDidMount() {
    window.scrollTo(0, 250);
  }
render() {
    return (
        <> 
        <div className="blog_body">
        
        <div className="blog_text">
       
          
          <div className="message_2U">
            <h2>Opening Image</h2>
        
            For today’s post I’d like to formally say hello, and talk about some things that come to mind when I think about writing. <br/><br/>
Life’s got a funny way of teaching you lessons on the way that things go, ones we keep learning again and again, and that’s what happens when you write too. I kind of think that writing is (among other things) interpreting circumstance. Understanding that things happen because other things have happened first.<br/><br/>
A novel, a poem, or a story- they all depend on some kind of opening image, something to tie everything else that happens together. A feeling, that leads to an action, or vice versa. A beginning that leads to an end, and all the things that occur in between. <br/><br/>
These are beautiful things. 
And they’re all around us, all the time. <br/><br/>
I myself dread time’s ticking clock, and writing brings me a lot of comfort in that I’ve attached it’s formation to something that I guess could be called God, although maybe that’s the wrong word for it, but either way...  Maybe I feel that way because of the way that writing somehow conjures <i>something out of nothing</i>. <br/> <br/>
In fact, it seems to pour out of people and then it’s so lovely and beautiful and perfectly apt and you think this must be connected to something beyond what it it is, because it just resonates so deeply, and because it moves people. 
<br/> <br/>
That’s how I feel anyway. Writing moves me. It has enriched my life and made me care about the world more. It’s made me care about myself more. It’s made me notice things around me. 
<br/><br/>
I think this is where I’ll leave it for today. If you’re reading this, I hope you’ll consider returning and thanks for being here.  
<br/><br/>
Silja

    
       </div>
        
       
        
        
       </div>
       <div className="back_blog">
        <Link to='/blog' className='write-links'>
                    < div className="button"> <i class="fas fa-chevron-left"></i> Back to blog</div>
        </Link>
        </div>
       </div>
    
    
    
        </>
      );
    }

  }
  
  export default Opening_Image;