import React, {Component} from 'react';
import './../Poem_item.css';



class ATBItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div >
    <div className="SSItem">
    <section id="ATB">
    <p>
    <h1>At The Brewery</h1>
    The machine is broken and so is the man behind it. He doesn't flinch, but watches as beer 

    piles up, and the conveyor belt continues to drag more bottles towards the heat of the action 
 
    which is now starting to smoke. The sound of the bottles clashing into each other and 
  
    smashing onto the floor fit perfectly with the other noises at the brewery. It's a chaotic 
    
    orchestra of shattering and clanging and grime and he can't remember the last time he didn't 
   
    smell beer. He wipes his brow. It’s awful hot in the brewery. 
    <br/><br/><br/>
    {
              this.state.showMe?
            <>
    
    An alarm blares, while the stench is so pungent and nauseating that it almost feels as 
 
    though the sweltering heat is really a fog made up entirely of ale. He is ailing, aching and all 
  
    he wants is to feel clean. Now buddy over here is screaming at him to stop the machine. For 
   
    God's sake! he says. He wonders what buddy knows of God, then takes a step forward and
 
    presses the big, red button. There, STOP. But it makes no difference to him and he doesn't 

    feel calmer now that the chaos has subsided. The pile up and the chaos is in him and he 
 
    imagines that beer is seeping from everyone of his pores.<br/><br/>


    His ears buzz in the aftermath of the alarm, and he admits to himself that he's buzzed. The 
   
    night before, like many other nights, had wrecked him, spitting him out his door and on the 
   


    way to work after only 3 hours of sleep, on an empty stomach parched from throwing up 12 
 
    beers and a mickey of vodka. On the way to work he collapsed and threw up neon-yellow 
 
    bile, which he sat next to for a minute or two, killing the half smoke he found in his chest 
   
    pocket, before getting up and continuing on. This memory is unforgiving, and he needs to 
    
    throw up again. He rushes past everyone, and sees buddy yell words he can't comprehend 
    
    and doesn’t care about, with particles of spit spraying from his open mouth amidst the 
 
    particles of beer in the hot fog that surrounds him and everyone else, in a room that's 

    shaking and suffocating him.Outside the sun meets him with equal hostility and there is not 

    a breath of fresh air to be found. He staggers to the curb and falls onto the concrete with his 

    palms extended out in front of him. His body sprawls out onto the road, his head prompted 
 
    up by the curb. He holds his hands out in front of him and sees that they are dirty, and 

    bleeding. He can smell dirt mixed with the usual, unshakable aroma, and it's like a sooty 
 
    cocktail that he's being forced to drink from. He presses his hands into the concrete and 

    focuses on the stinging, throbbing pain searing through his palms. His eyes barely open, and

    with his mouth ajar, a moan leaving it, he squints past the sun and sees that he's lying next 
    
    to a sewage grate. He reaches to touch it and is met by the cold embrace of steel. He lets 
 
    out a deep pitted cry, and adrenaline surges through him as tears trickle down his cheek into 
   
    the dirt. With the very last bit of strength he has he pushes his head forward, planting his 

    cheek onto the cool dented surface.
    <br/><br/>
    There is relief, and freedom from the pain. Anything other than that plastering heat. 

    His heart beat steadies, but tears keep flowing, into the drain, into the rainwater, into 
   
    shit, and urine, and garbage. But the cold dulls his headache and he can think clearly 
    
    now, for the first time in days. Maybe it's been weeks. Maybe it’s been years. It has
   
    been years, and it's been hard on him. He's tired, and he needs a glass of water. He 

    lays still on the curb, sobbing and baking in the sun but still protected by the grate for 
  
    another ten minutes before he can gather the strength to stand, but then he does it,
 
    and he is upright again, his back no longer crooked, his shoulders no longer 
 
    shrugging and straining on his head to sink low. Something in the fall broke that. 
    <br/><br/>
    He walks back inside but holds his breath this time, unwilling to smell beer even for 
  
    just one more second. He empties his locker then heads into the bathroom, where he 
  
    stares in the mirror there, cupping water into his desert-dry mouth directly from his 

    hands. He tastes the dirt, and the blood, and for one last time: the subtle, yeasty 
  
    taste of beer, but mostly he tastes clean, pure, cold water, and he drinks till he is no 
   
    longer thirsty. 
    <br/>




            
          

            </>

            :  <div className="fade_out"></div>
            }
    </p>
    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default ATBItem;
