import React, {Component} from 'react';
import './../Poem_item.css';



class HHItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="SSItem">
    <section id="HH"> 
    <h1>Hardened Hands</h1>
  
   

    Four weeks in, I watch the sun set into Denmark’s stretching sea. 
    My sister’s beside me. We’ve been together since this all started. 
    I flew back home to Denmark when the news broke out. 
    I finally breathed easy, when my plane touched down, and now a month
    later it’s another beautiful night and the whole sky is crimson pink
    and orange too. It stretches on forever.
    <br/><br/><br/>
    {
              this.state.showMe?
            <div>
                Last week, my family sat quietly round the kitchen table as the prime-minister addressed
                 the country. My father tuned the radio. I stared out our window at an empty Ebeltoft and 
                 thought of other people sitting in the other houses here, hearing the same news. 
                 I imagined a town completely quiet. At the grocery store, I give space where I can, 
                 make space between the aisles. Look to see, if I can see any of the same fears that I have, 
                 in someone else’s eyes. Rub sanitizer into my palms on my way in, again on my way out. 
                 Wash it off with soap when I come home. At nighttime, I ask my mother for hand lotion. 
                 I smooth it on, at the foot of her bed. Rub what’s left onto her tired feet.<br/><br/><br/>

                 <i>Originally published in Politiken</i><br/>
                 <i>200 Words</i><br/><br/>

                 <i> 4 weeks (!) into COVID19, myself and a few hundred people from all over the world 
                 shared their thoughts in 200 words on life under the pandemic for the Danish newspaper Politiken. 
                 Give their testimonies a read<a href="
https://politiken.dk/udland/art7756836/Karina-er-vant-til-isolationen.-Hun-bor-p%C3%A5-Svalbard-med-hele-familien-og-300-sl%C3%A6dehunde.-Her-er-det-helt-andre-ting-man-frygter">here.</a> </i>

            </div>

            :  <div class="fade_out"></div>
            }
  
    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default HHItem;
