import React from 'react';
import '../../App.css';
import '../project.css';
import ProjectItem from '../Project_item';


function Projects() {
  return (
    <>    

    
    <div className="project_container">
      
    <div className="project_divide"> <div className= 'title_box'></div></div>
    <div className="MobileProjects_Img">
        <img src="/images/First_print.jpg" alt="Cozy"/>
    </div>
    <ProjectItem
    firstfirst='true' 
    first={
      <div className=" project_details">
	
      <h2>	Publishing Credits </h2>
      <hr/>
      <br/>
     <div className="credits_list">
     <div className="credit">
        <a href="https://twitter.com/ducklakebooks?lang=en">Duck Lake Journal</a> <br/>
        Volume 3 <br/>
        Dec. 2018 <br/>
        “Trumpet’s Ode to Clarinet” <br/>
      </div>
      <div className="credit">
      <a href="https://www.meniscus.org.au/">Meniscus Literary Journal</a> <br/>
      Volume 7 Issue 2<br/>
      Nov. 2019<br/> 
      “It will be love that kills me” <br/>
      “We became wolves” <br/>

      </div>
      </div>
    </div>
    } 
    second={	<div className="project_pics">
      <img src="/images/balls2.jpg" alt="Cozy"/>
    
      
      </div>} />
    
    
    <div className="project_divide"></div>
    
    <ProjectItem
    firstfirst='true' 
    first={	<div className="project_pics">
      <img src="/images/First_print.jpg" alt="Cozy"/>
      <figcaption>Picking up printed first draft, Summer 2020</figcaption>
      
      </div>} 

    second={<div className="project_details">
	
      <h2>Suspense Novel </h2>
      <hr/>
      <br/>
      This summer I finished the first draft of what I hope will be my debut novel and I have been chipping 
      away at it since then. I’m at the querying stage, and can’t wait to share updates on how that goes.
      <br/><br/>
      
      Without telling you too much (it is a suspense novel after all), the book I’ve been writing is about
      family secrets, and follows 31-year-old Rachel, as she returns home to uncover the real truth 
      behind the death of her stepfather and disappearance of her little sister fifteen years ago.
      
    
    </div>}/>
    <div className="project_divide"></div>
    <ProjectItem
    firstfirst='false' 
    first={	<div className="project_pics">
      <img src="/images/Open_Mic_Club.jpg" alt="Cozy"/>
      <figcaption>Photo credit: 
Peter Valckx from Advalvas Magazine</figcaption>
      
      </div>} 

    second={
      <div className='project_details'>
	
	    <h2>VUOpenStage For Poets & Storytellers </h2>
      <hr/>
	
      Founder, Organizer & Host<br/><br/>
      Amsterdam, the Netherlands 2018-2021 <br/>
      <i>Nominated for:&nbsp; 
      <a href="https://www.vu.nl/en/about-vu-amsterdam/organization/student-representation/studentinitiative/index.aspx">
        Student Initiative Prize at Vrije University</a> <br/> </i> <br/><br/>
      This group brings students of the VU together to celebrate <br/>
      the literary culture of the campus,
      featuring guest poets from<br/> surrounding areas and beyond. 
      </div>
  }/>
   <div className="project_divide"></div>
<ProjectItem
    firstfirst='true' 
    first={	<div className="project_pics">
      <img src="/images/EF.jpg" alt="Cozy"/>
      <figcaption>Expanded Field accepts submissions in all genres, all year round. Check out their guidelines <a href='https://www.expandedfieldjournal.com/submissions' >here</a> <br/>  <br/> 

Cover design by Angelo Zinna. You can see the other kinds of things he does by clicking the link <a href='https://angelozinna.com/'>here</a>
</figcaption>
      
      </div>} 

    second={
      <div className="project_details">
		
       <h2>	Other Credits </h2>
       <hr/>
      <h3>	Expanded Field Literary Magazine </h3>
     
      
    
  
      Submissions Editor 2018- 2019
      Vrije University, Amsterdam, Netherlands
  
    
      <h3>City Council Meeting: Mental Health in the City</h3>
  
      Selected to read among other poets at a council meeting centrified around mental health awareness in the city of Victoria.
      Victoria, British Columbia, Canada 2017
      
      <h3>Nyborg Gymnasium & Boarding School:</h3>
      Event Coordinator and MC for annual Awards Night ceremony
      2009-2012
  
    
      
    
    </div>
  }/>
   <div className="project_divide"></div>
    </div>
    </>
  );
}

export default Projects;
