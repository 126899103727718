import React, {Component} from 'react';
import './Poem_item.css';



class PoemItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="poemItem">
    <section id={this.props.section}>
    
        <a href={this.props.Link}><h1>{this.props.Title}</h1></a>
         Originally published in {this.props.publisher} <br/>{this.props.Issue} <br/>
         {
              this.props.set_id? <>Read on {this.props.poem_id}</> :null
         }
          
    </section>
  
    </div>
    
</div>

);
}
}

export default PoemItem;
