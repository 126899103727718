import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function RecentBlogItem(props) {
  return (
    <>
     <div className={props.classID}>   
     <Link
                to={props.path}
                className='write-links'>
                <h3>{props.Title}</h3>    
      </Link>
                <h4>{props.Date}</h4>
                <div className="divider  div-transparent "></div>
                <br/>	
                {props.Preview}
                
      <Link
                to={props.path}
                className='write-links'
              >
                < div className="button">Read More</div>
         </Link>
      </div>
    </>
  );
}

export default RecentBlogItem;
