import React, { Component } from 'react';
import BlogList from '../blog_Listing';
import '../../App.css';
import '../Blog.css'
class Blog extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 50);
  }
  render() {
  return (
    <>  
    <div className="Home_divider"></div>
    <BlogList/>
    </>
  );
}
}
export default Blog;
