import React from 'react';
import './home.css';



function ReadingMenu() {
  return (
<div className="menu">
	<div className="btn-group">
	  <h2><a href="#POEMS">POEMS</a></h2>
	  <div className="poemMenu" >
	  <h3><a href="#WBL">It will be love that kills me</a></h3>
	  <h3><a href="#WBW">We became wolves</a></h3>
	  <h3><a href="#TOTC">Trumpet’s Ode to Clarinet</a></h3>
	  <h3><a href="#WPBW">The wind picked up by the water</a></h3>
	  <h3><a href="#ISH">I sing “Hallelujah!” because
I think God might be listening</a></h3>
	  <h3><a href="#FOBTH">Far out beyond the horizon, where heaven meets earth</a></h3>
	   </div>
	  <h2><a href="#SS">SHORT STORIES</a></h2>
	  <div className="poemMenu" >
	  <h3><a href="#ATB">At The Brewery</a></h3>
	  </div>
	  <h2><a href="#Others">OTHER WORKS</a></h2>
	  <div className="poemMenu" >
	  <h3><a href="#HH">Hardened Hands</a></h3>
	  </div>
	</div>
</div>
  );
}

export default ReadingMenu;