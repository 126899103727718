import React from 'react';
import WritingItem from './Writing_item';


function BlogList() {
  return (
    <>

     <div className="Blog_list">
		 <div className="Container_Blog_list">
			<div className="works_pic">
			</div>
			<div className="works_list">
				<h1>Blog</h1>
				<hr/>
			<WritingItem
              type=''
              Date='Jan 30, 2021'
              Title='Opening Images'
			  Preview="For today’s post I’d like to formally say hello, and talk about some things that come to mind when I think about writing.
                    Life’s got a funny way of teaching you lessons on the way that things go, ones we keep learning again and again, and that’s what happens when you write too. I kind of think that writing is (among other things) interpreting circumstance. Understanding that things happen because other things have happened first. 
                    A novel, a poem, or a story- they all depend on some kind of opening image, something to tie everything else that happens together. A feeling, that leads to an action, or vice versa. A beginning that leads to an end, and all the things that occur in between.
                    "
              path='/opening_images'

            />
			
				<hr/>
			<WritingItem
                    type=''
					Date='Feb 5, 2021'
					Title='Letting go is hard'
					Preview='Those of you who know me personally may know that I’ve been working on a novel for the last year and a bit, one that I hope to eventually publish traditionally. The story itself took a little under a year to write once I hunkered down, and during that process I felt pretty damn good about it. 
              Of course, that didn’t last. This is where I’ll get into the editing aspect of fiction; a whole different ballgame from drafting, and ultimately where your novel will shape itself around the story you wrote. Even though I’ve technically made the decision at this point that I’m done, that it’s done, and all that’s left is to rip off the bandaid and submit it, I sometimes have to restrain myself from going back for a further round of edits. Afterall, I’ve already done six of them. 
              ' 
					path='/letting_go'
            />
             
		
                <hr/>
			</div>
			
		</div>
     

	</div>

    </>
  );
}

export default BlogList;
