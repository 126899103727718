import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Readings from './components/pages/Readings';
import Projects from './components/pages/Projects';
import Contact from './components/pages/Contact';
import Blog from './components/pages/Blog';
import About from './components/pages/About';
import Opening_Image from './components/pages/Opening_Image';
import Letting_Go from './components/pages/Letting_Go';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <div className="MobileTitle_container">
        Silja Liv Kelleris
        <div className='MobileTitle_subtext'>
				      POET &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; NOVELIST &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  READER  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SPEAKER
			  </div>
        </div>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/writings' component={Readings} />
          <Route path='/projects' component={Projects} />
          <Route path='/contact' component={Contact} />
          <Route path='/blog' component={Blog} />
          <Route path='/About' component={About} />
          <Route path='/opening_images'  component={Opening_Image} />
          <Route path='/letting_go'  component={Letting_Go} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
