import React, {Component} from 'react';
import './../Poem_item.css';



class ISHItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="poemItem">
    <section id="ISH">
  
      
        <h1>I sing “Hallelujah!” because <br/>I think God might be listening 
</h1><br/>
            (Start of COVID19, March 2020) <br/> <br/>

            It is day ten. It's only day ten.<br/>
             It’s been going so slowly.<br/>
            At this point, the newness of it all has shed itself,<br/>
            revealing only more time to wait.<br/>
            All novelty, however outlandish, has worn off.<br/>
            There’s a tension to the air,<br/>
            though it’s not between people really,<br/>
            but inside of them instead.<br/><br/>

            {
              this.state.showMe?
            <div>
                It's this kind of fumbling, awkward, untrained slowness,<br/>
                that needs to be relearned,<br/>
                as we peel ourselves from schools, offices, parks,<br/>
                spaces with more than 10 people, etc.<br/>
                Et cetera, et cetera.<br/>
                You’ve read the regulations.<br/>
                The party's been cancelled; 
                the whole damn thing is off.<br/>
                You’ll have to wait with your dream wedding too.<br/><br/>

                The borders are closing, but I’m not ready to go yet.<br/>
                Doesn’t matter, they are calling everyone home.<br/>
                I miss the boat, by the stretch of a hair.<br/>
                I’ll get the next one.<br/>
                That first one just came too quick.<br/>
                I have enough weed to get me through to next week,<br/>
                but I’m not slowing down, so maybe not. <br/>
                Same goes for the coffee. <br/>
                And the smokes actually too.<br/>
                With all this free time, <br/>
                I should really learn to pace myself.<br/>
                Out of toilet paper? Out of luck.<br/>
                Sorry.<br/><br/>

                But the doctors are saying it’s not about luck;<br/>
                it’s about odds at this point.<br/>
                But who knows best? Is it WHO? Or is it my mother?<br/>
                What about Zuckerberg, and all his Facebook friends?<br/>
                Do they know? Do mine know?<br/><br/>

                I pour water in my whole milk to make it skim.<br/>
                To skimp. To make it last longer. <br/>
                I feel as though I am adjusting.<br/>
                I feel like I got what it takes<br/> 
                to survive this easy kind of disaster.<br/><br/>

                I invent new recipes to eat alone in my apartment.<br/>
                Pulverized mashed potatoes and applesauce.<br/>
                Skip the chewing and absorb with minimal effort.<br/>
                Skip the cooking too.<br/>
                I need to conserve my energy; that’s quarantine 101.<br/>
                Instant meals and instant messaging,<br/>
                I catch up with people from before.<br/>
                I say the same ‘holy shit, yeah it’s crazy’ <br/>
                to about twenty different people.<br/><br/>

                Find myself missing them.<br/>
                Find myself missing all of them. All people.<br/>
                Find myself thinking about all people.<br/><br/>

                Thinking about other people, in other places.<br/>
                In bad, bad places. In bad, bad ways.<br/>
                Search the internet for news<br/>
                out of refugee and detainment camps.<br/>
                Wonder how they can count the sick <br/>
                when the sick can’t count on them.<br/>
                Remember that no news<br/>
                is not always good news.<br/><br/>

                Realize it could just as well be me,<br/>
                and consider that this might just show me that.<br/>
                I contemplate my privilege.<br/>
                I pray for myself, then I pray for everyone else as well,<br/>
                as best as I can without much practice.<br/>
                I’ve never really had the time before.<br/><br/>

                Someone at the apartment across the way<br/>
                begins to sing and then someone else does too.<br/>
                I sing “Hallelujah!” <br/>
                because I think God might be listening,<br/>
                and then perhaps he really is,<br/>
                because a flock of blackbirds<br/>
                pour across the sky in perfect harmony<br/>
                to the songs coming from people’s windows now,<br/>
                and I think ‘Okay, now that’s pretty special’<br/>
                and that becomes a sliver of a silver lining to all this.<br/>

            </div>
            :  <div className="fade_out"></div>
            }
  
    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default ISHItem;
