import React, {Component} from 'react';
import './../Poem_item.css';



class FOBTHItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="poemItem">
    <section id="FOBTH">
    <h1>Far out beyond the horizon,<br/> where heaven meets earth</h1><br/>
  
      
        
            From up here, where the sky dips into the ocean,<br/>
            where heaven’s skies meet the earth’s oceans, <br/>
            there is only blue. There is only sky, <br/>
            and sky on a still ocean, and it is only blue. <br/>
            This is how I remember that the universe is infinite. <br/>
            And on a day where our love for you leaves us in prayers:<br/><br/>
                {
              this.state.showMe?
            <div>
                “Peace be with you”<br/>
                “Shalom Aleichem”<br/>
                “Bless this child, who has died”<br/><br/>

                and as it seeps from us in weeping,<br/>
                and when we mourn for you in hymns,<br/>
                it is this same love that lays you to rest. <br/>
                Godchild, there are angels at work. <br/>
                Daughter, there is magic in the air. <br/>
                Sister, it is pouring from our hearts. <br/>
                Friend, you will stay with us forever. <br/>
                Can’t you see it?<br/>
                They have given us this sunset,<br/>
                and the infinity that it moves in,<br/>
                which you now move in too.<br/>
                Did you hear our prayers?<br/>
                Were you with us, when we sang for you?<br/>
                Did you weep, when we wept for you?<br/>
                And as the sun sets over the place where you were born,<br/>
                and the sky stretches out for forever,<br/>
                are you as moved by the life that you lived, as we are?<br/>
                And can you feel the comforts of home,<br/>
                before the sun sets on its big blue sky,<br/>
                as it seeps out into night time,<br/>
                where we’ll see you in the stars,<br/>
                and in the black night sky’s moon. <br/>



            </div>
            :  <div class="fade_out"></div>
            }
    
    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default FOBTHItem;
