import React, { Component } from 'react';
import '../../App.css';
import '../Blog.css';
import '../project.css'
import { HashLink as Link } from 'react-router-hash-link';
class Letting_Go extends Component {
 
    componentDidMount() {
      window.scrollTo(0, 250);
    }
  render() {
    return (
      <>  
      <div className="blog_body">
      <div className="blog_text">
    
        
        <div className="message_2U">
          <h2>Letting Go Is Hard</h2>
      
        
  Those of you who know me personally may know that I’ve been working on a novel for the last year and a bit, one that I hope to eventually publish traditionally. The story itself took a little under a year to write once I hunkered down, and during that process I felt pretty damn good about it. 
  <br/><br/>
  Of course, that didn’t last. 
  <br/><br/>
  This is where I’ll get into the editing aspect of fiction; a whole different ballgame from drafting, and ultimately where your novel will shape itself around the story you wrote. Even though I’ve technically made the decision at this point that I’m done, that it’s done, and all that’s left is to rip off the bandaid and submit it, I sometimes have to restrain myself from going back for a further round of edits. Afterall, I’ve already done six of them. 
  <br/><br/>
  During the first draft process I thought my writing was fairly clean. I was hoping I would get the job done with two rounds, three tops. Maybe I was overly confident in myself or my passion for writing, or maybe I just underestimated what goes into the entirety of story crafting, especially during a first real go at it.  
  <br/><br/>
  But there’s nothing like that initial bout of edits to knock you down a few pegs, which is what happened, and which ultimately was a very good thing for me, though still a bit of a shock during the thick of it. You know how many times the word “just” showed up in my first draft? 309 times. ‘Find and Search’ had it glaring back at me 3, 4 sometimes even 5 times on a given page. Enter the shock to the system. Enter with that, the crippling sense of self doubt. I ended up spending upwards of a month plucking my manuscript for filler words, any instances of any type of repetition, one word after the other, tracking where they had first appeared and then racking my brains over whether or not this was a situation that it was okay it appeared again 30 pages later. 
  <br/><br/>
  And that was just the start of it. My dialogue had this weird habit I didn’t notice during drafting, of bouncing names back and forth between whoever was talking. This is not from my book, in fact it’s not from anything in particular, but to give you an idea of what I mean: 
  <br/><br/>
  <i>“Jack! How are you?” 
  “I don’t know about you, John, but I’m getting pretty sick of being on this island.” 
  “See, Jack, I just don’t get that. I mean, I think being on this island rocks.” 
  “That’s great for you, John. Kinda sucks for everyone else though.”  </i>
  <br/><br/>
  (Not from ABC’s LOST, at least not directly.) 
  <br/><br/>

  Telling myself that I had done enough was harder than anything. I pushed my submission deadline back one week, then another, then another. And I’m glad I did. It’s made my book more cohesive and concise. It tested my skills as a self-editor and showed me I could do it. It also means that I’ve done my absolute best, so no matter the outcome, I can hold myself to that. I’d feel way, way worse if I typed THE END, then promptly sent the manuscript without doing the research to fill me in on the standard of the industry and of the craft, and ultimately getting rejected because I missed the next step in the process. And editing really is the next step in the process. 
  <br/><br/>
  One that you eventually have to move on from too. 
  <br/><br/>
  A few days ago, after I officially let go and sent the final draft with a query to a small, small handful of literary agents (I’m still a bit gun-shy) I found myself beginning to obsess over commas. I struggle with grammar, so undoubtedly there’s commas where there shouldn’t be in my manuscript. I thought what’s the harm in doing one more ‘Find and Search’, to scope out my errors and whatnot. Upon typing “,” it pulled up 7000 instances. I started sorting through them and got about 10 pages in when I decided to stop. Decided there’s a limit and I’d reached it. My head hurt, my eyes strained and another cycle of self-doubt was turning inside me. I made the decision that this was something I needed help with, and that all things considered was an easy enough fix which, if my pages and query were strong enough, an editor or agent would gladly help me iron out. 

  <br/><br/><br/>
  From what I’ve learned about building a writing career, self-doubt and hyper self-criticism comes with the territory and it is something you have to just work through. In the big picture, these edits are little things, assuming you deal with them. In fact they are less than little most of the time, since they are what get cut in the edits. During the process I realized  that nearly no manuscript starts off ready for submission. Mine certainly didn’t. It takes time, a willingness to work to improve it, an open and critical mind towards what’s lacking or what doesn’t suit the story, and finally the belief in yourself to carry you through it. 
  <br/><br/>
  I’ll end it on that. To those of you drafting or editing or waiting to hear back on a query: keep going! To everyone else, thanks for being here. 
  <br/><br/>

  Silja 
    </div>
      
    
      
      
    </div>
    <div className="back_blog">
    <Link to='/blog' className='write-links'>
                < div className="button"> <i class="fas fa-chevron-left"></i> Back to blog</div>
    </Link>
    </div>
    </div>



      </>
    );
  }
}

export default Letting_Go;