import React from 'react';
import './home.css';

function WaterColorPoster() {
  return (
    <div className="Landing_page">
	<h2>"Someone at the apartment across the way begins to sing,<br/>
		and then someone else does too.<br/>
		I sing Hallelujah! because I think God might be listening,<br/>
		and then perhaps he really is, because a flock of blackbirds<br/>
		pour across the sky in perfect harmony<br/>
		to the songs coming from people’s windows now,<br/>
		and I think ‘Okay, now that’s pretty special’<br/>
		and that becomes a sliver of a silver lining to all this."<br/>
		 
	</h2>
	<h3> - <i> I sing Hallelujah! because I think God might be listening</i>   (March, 2020)   </h3> <br/>
</div>
  );
}

export default WaterColorPoster

