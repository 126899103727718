import React from 'react';
import './home.css';
import SubForm from './sub_form';



function Footer() {
  return (
    <div className='footer'>
       <div className='footer_hr'></div>
       
      
      <div className='sk_links'> 

         <SubForm/>
      <div className='sk_icons'>
        <h3>Follow me on</h3>
          <div className='icons'><a href="https://www.goodreads.com/user/show/98763302-silja-kelleris" className="fab fa-goodreads fa-2x"> </a></div>
          <div className='icons'><a href="https://www.instagram.com/slivkell/" className="fab fa-instagram-square fa-2x"> </a></div>
          <div className='icons'><a href="https://twitter.com/SiljaKelleris" className="fab fa-twitter-square fa-2x"> </a></div>
        </div>
     
        </div> 
        
        <div className='sk_footer'>
          
        <hr/>
        <h3> Designed by fkelleris</h3>
        </div>
    </div>
  );
}

export default Footer;
