import React from 'react';
import '../../App.css';
import '../About.css';
import ProjectItem from '../Project_item';

function About() {
  return (
    <>  
    <div className="Contact_body">
    
    <div className="About_container">
   <ProjectItem
    firstfirst='true' 
    second={
      
      <div className="message_2U">
      
                <h2>ABOUT ME</h2>
            
              
                I write about all sorts of things. I do it partly because I feel like I have to and partly because it makes me feel good. That’s probably what it comes down to: writing feels good for writers. That’s why we have to do it. 
                I write about the people that hold me up, my friends and family. About those that aren’t here anymore and about the things that scare me the most. Of course, I also write about the things that make me feel good, like writing itself for example.
                Most of my short stories center around the extraordinary things that exist inside the mundane and my novels are typically small-town tales of secrets and grit. Mania sometimes sneaks in as well.
                Once again thank you for visiting and I do hope you’ll come back.
                       
                <br/> <br/> 
                Silja Kelleris
                
                <div className="PPS">
                    <br/>
                    <br/>
                    PS: You might also be interested to know that I like coffee, dogs, food, and laughing with my very best girl friends. Laughing with all friends, really. I’ve split my residency between Denmark (where I was born), Canada (where I grew up) and most recently the Netherlands (where I went to university). I am 29, and I smoke cigarettes even though I know they are very bad for me. I do hope to quit by the time I’m 30 though. Tips for quitting are most welcome. I currently reside in Victoria BC, which lies on the unceded territory of the Lekwungen people. 


                </div>
   </div>
    
    } 
    first={	<div className="About_pics">
      <img src="/images/author_website3.jpg" alt="Cozy"/>    
      </div>} />
    
    
   </div>
   </div>
   </>
  );
}

export default About;