import React, {Component} from 'react';
import './../Poem_item.css';



class TOTCItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="poemItem">
    <section id="TOTC">
    
      
        <h1>Trumpet’s Ode to Clarinet</h1><br/>
        
        <i>Originally Published in Duck Lake Books Volume 3</i><br/><br/>
		Poem in response to: Olivier Clements & Dissonant Histories
		at Hermann's Jazz Club: You're Not Thinking of Me.<br/>
		 
		 <br/>
		<iframe className="Hezz_vid" width="300" height="200" title="Jazz Club" src="https://www.youtube.com/embed/1r-_HcO4Oew">
		</iframe> <br/> <br/>  
             {
              this.state.showMe?
            <div>
            The lonely howl of Trumpet<br/>
            can still be heard bellowing<br/>
            through the smoke-stained walls <br/>
            of Hermann's Jazz Club.<br/>
            He coos sorely begging to once again<br/>
            hear the pining whisper<br/>
            of his old love, Clarinet.<br/><br/>
         
            As he plays his sound, he closes his eyes <br/>
            and sees the two of them<br/>
            slow dancing to the tremble <br/>
            of the lonely Snare
            who warped and bent,<br/>
            still never misses a beat;<br/>
            even after all these years.<br/><br/>

           
            Bass brings first the fear, then the thrill as<br/>
            Trumpet blows smoke at Clarinet.<br/>
            He sees them wailing together, <br/>
            under a spot-light,<br/>
            stopping to breathe <br/>
            only on cue from Triangle,<br/>
            who sits and waits in the corner<br/>
            ready to chime in.<br/><br/>

            Trumpet opens his eyes, <br/>
            and the music disappears.<br/>
            
            So, he closes them again <br/>
            and keeps playing into the night.<br/>
            </div>
            :  <div className="fade_out"></div>
            }
   
    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default TOTCItem;
