import React, {Component} from 'react';
import './../Poem_item.css';



class WPBWItem extends Component {
  constructor(){
    super()
    this.state ={
      showMe: false,
    }

  }

  operation(){
    this.setState({
      showMe:!this.state.showMe

    })

  }
render(){
return (
<div className="writeItem">
    <div className="poemItem">
    <section id="WPBW">
      
        <h1>The wind picked up by the water</h1><br/>
        Words of anger spewing, seeping, <br/>
        spilling into the sea,<br/>
        past the bay of bitterness. <br/>
        From the hurricane of her finally having left him, <br/>
        a wind that wheezed and whistled:<br/><br/>
        <i>I wish I had never met you.</i><br/><br/>

            {
              this.state.showMe?
            <div>
                            
            It sprayed over the sea wincing:<br/> <br/>

            <i>It’s just too hard to live without you.</i><br/><br/>

            The words washed over her again and again<br/>
            until the jagged rocks of her hardness<br/>
            were sanded down into loose pebbles,<br/>
            constantly being moved and bothered, <br/>
            with no grip left in them.<br/>
            </div>
            :  <div className="fade_out"></div>
            }

    </section>
  
    </div>
    < div className="button" onClick={() => this.operation()}> {this.state.showMe? <>Show Less</>  : <>Show More</> }</div>
</div>

);
}
}

export default WPBWItem;
