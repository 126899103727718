import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function WritingItem(props) {
  return (
    <>
     <div className="R_work">
      <h3>{props.type}</h3>
     <Link
                to={props.path}
                className='write-links'>
                <h2>{props.Title}</h2>
              
      </Link>
        
        <div className="R_date">{props.Date}</div>
         <div className="piece_desc">{props.Preview}</div>
         <Link
                to={props.path}
                className='write-links'
              >
                < div className="button">Read More</div>
         </Link>
       
      </div>
    </>
  );
}

export default WritingItem;
