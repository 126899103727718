import React from 'react';
import './home.css';



function Intro() {
  return (
    <div className='intro'>
      <div className='welcome_text'>
      <h1>Hello and Welcome!</h1>
		<h2>Check back or subscribe for updates on publications,	
			upcoming projects as well as my thoughts on writing. <br/> 
			-Silja
		</h2>	
	

      </div>
     
    </div>
  );
}

export default Intro;
